<template>
    <Card>
        <div class="info">
            <div class="info_text">
                <strong>Nome:</strong>
                <span>{{ nome }}</span>
            </div>

            <div class="info_text" v-if="tipo_usuario != 'comercial'">
                <strong>Loja:</strong>
                <span>{{ loja }}</span>
            </div>

            <div class="info_text">
                <strong>Tipo:</strong>
                <span>{{ tipo_usuario }}</span>
            </div>

            <div :class="{ 'info_wrap' : tipo_usuario == 'comercial'}">
                <div class="info_buttons">
                    <Button texto="Editar" @click="editarUsuario()" />
                    <Button texto="Alterar Senha" isRemove @click="alterarSenha()" />
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';

export default {
    components: {
        Card,
        Button,
    },

    props: {
        nome: {
            required: true,
        },
        loja: {
            required: true,
        },
        categoria: {
            required: true,
        },
        id: {
            required: true,
        },
        tipo_usuario: {
            required: true,
        },
    },

    methods: {
        editarUsuario() {
            const usuario = {
                loja: this.loja,
                nome: this.nome,
                id: this.id,
                tipo_usuario: this.tipo_usuario,
                categoria: this.categoria,
            };

            this.$emit('editarUsuario', usuario);
        },

        alterarSenha() {
            const usuario = {
                loja: this.loja,
                nome: this.nome,
                senha: this.senha,
                id: this.id,
            };

            this.$emit('alterarSenha', usuario);
        },
    },
};
</script>

<style lang="scss" scoped>
.info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;

    &_text {
        display: flex;
        justify-content: space-between;
    }

    &_buttons {
        display: grid;
        grid-template-columns: 1fr 1.2fr;
        gap: 15px;
    }
}
</style>
