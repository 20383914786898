<template>
    <Overlay @fechaOverlay="$emit('fechaOverlay')">
        <Card>
            <form class="form" @submit.prevent="salvaNovoUsuario()">
                <div :class="{ error: nomeInvalido }">
                    <label for="nome">Nome:</label>
                    <input type="text" id="nome" name="nome" v-model="nome" />
                </div>

                <div>
                    <label for="loja">Loja:</label>
                    <select name="loja" id="loja" @change="lojaHandler">
                        <option v-for="loja in lojas" :key="loja.loja" :value="loja.loja">{{ loja.loja }}</option>
                    </select>
                </div>

                <div :class="{ error: senhaInvalida }">
                    <label for="senha">Senha:</label>
                    <input type="text" id="senha" name="senha" v-model="senha" />
                </div>

                <div>
                    <label for="tipoUsuario">Tipo:</label>
                    <select name="tipoUsuario" id="tipoUsuario" @change="tipoHandler">
                        <option value="gestor">gestor</option>
                        <option value="comercial">comercial</option>
                    </select>
                </div>

                <div class="checkbox" v-if="tipo_usuario == 'comercial'">
                    <p>Categorias:</p>
                    <div class="checkbox_categorias">
                        <div class="checkbox_wrap" v-for="categoria in categorias" :key="categoria.categoria_2">
                            <input
                                type="checkbox"
                                :id="categoria.categoria_2"
                                :value="categoria.categoria_2"
                                name="categorias"
                                v-model="categoriasChecadas"
                            />
                            <label :for="categoria.categoria_2">{{ categoria.categoria_2 }}</label>
                        </div>
                    </div>
                </div>

                <small class="form_error-msg" v-if="senhaInvalida">Senha não pode ter menos que 4 caracteres</small>
                <small class="form_error-msg" v-if="nomeInvalido">Nome não pode ter menos de 4 caracteres</small>

                <Button texto="Salvar" />
            </form>
        </Card>
    </Overlay>
</template>

<script>
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';
import Overlay from '../UI/Overlay.vue';

export default {
    components: {
        Card,
        Button,
        Overlay,
    },

    props: {
        lojas: {
            required: true,
        },
        categorias: {
            required: true,
        },
    },

    data() {
        return {
            nome: '',
            loja: '',
            senha: '',
            categoriasChecadas: [],
            tipo_usuario: 'gestor',
            senhaInvalida: false,
            nomeInvalido: false,
        };
    },

    created() {
        this.loja = this.lojas[0].loja;
    },

    watch: {
        nome(newValue) {
            if (newValue.trim().length > 4) {
                this.nomeInvalido = false;
            }
        },
        senha(newValue) {
            if (newValue.trim().length > 4) {
                this.senhaInvalida = false;
            }
        },
    },

    methods: {
        tipoHandler(event) {
            this.tipo_usuario = event.target.value;
            this.categoriasChecadas = [];
        },

        salvaNovoUsuario() {
            if (this.validaCriacao() == false) return;

            const user = {
                nome: this.nome,
                loja: this.loja,
                senha: this.senha,
                tipo_usuario: this.tipo_usuario,
                categoria: this.categoriasChecadas,
            };

            this.$emit('salvaNovoUsuario', user);
        },

        lojaHandler(event) {
            this.loja = event.target.value;
        },

        validaCriacao() {
            if (this.nome.trim().length <= 4) {
                this.nomeInvalido = true;
                return false;
            }

            if (this.senha.trim().length <= 4) {
                this.senhaInvalida = true;
                return false;
            }

            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.checkbox {
    display: block;

    p {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    &_categorias {
        display: grid;
        gap: 15px;
        font-size: 1rem;
        height: 250px;
        overflow-y: auto;
        overflow-x: hidden;

        @media (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    input {
        width: auto;
    }

    &_wrap {
        justify-content: unset;
    }
}
</style>
