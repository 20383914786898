<template>
    <Overlay @fechaOverlay="$emit('fechaOverlay')">
        <Card>
            <form class="form" @submit.prevent="salvaEdicao()">
                <div :class="{ error: lojaInvalida }" v-if="editTipoUsuario != 'comercial'">
                    <label for="loja">Loja:</label>
                    <input type="number" id="loja" name="loja" step="1" v-model="editLoja" />
                </div>

                <div :class="{ error: nomeInvalido }">
                    <label for="nome">Nome:</label>
                    <input type="text" id="nome" name="nome" v-model="editNome" />
                </div>

                <div>
                    <label for="tipo_usuario">Tipo:</label>
                    <select name="tipo_usuario" id="tipo_usuario" @change="tipoHandler">
                        <option value="gestor" :selected="tipo_usuario == 'gestor'">gestor</option>
                        <option value="comercial" :selected="tipo_usuario == 'comercial'">comercial</option>
                    </select>
                </div>

                <div class="checkbox" v-if="editTipoUsuario == 'comercial'">
                    <p>Categorias:</p>
                    <div class="checkbox_categorias">
                        <div class="checkbox_wrap" v-for="categoria in categorias" :key="categoria.categoria_2">
                            <input
                                type="checkbox"
                                :id="categoria.categoria_2"
                                :value="categoria.categoria_2"
                                name="categorias"
                                v-model="categoriasChecadas"
                            />
                            <label :for="categoria.categoria_2">{{ categoria.categoria_2 }}</label>
                        </div>
                    </div>
                </div>

                <small class="form_error-msg" v-if="lojaInvalida">Loja não pode estar vazio.</small>
                <small class="form_error-msg" v-if="nomeInvalido">Nome não pode ter menos de 4 caracteres</small>

                <Button texto="Salvar" />
            </form>
        </Card>
    </Overlay>
</template>

<script>
import Overlay from '../UI/Overlay.vue';
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';

export default {
    components: {
        Overlay,
        Card,
        Button,
    },

    props: {
        nome: {
            required: true,
        },
        loja: {
            required: true,
        },
        id: {
            required: true,
        },
        tipo_usuario: {
            required: true,
        },
        categoria: {
            required: true,
        },
        categorias: {
            required: true,
        },
    },

    data() {
        return {
            lojaInvalida: false,
            nomeInvalido: false,
            editNome: '',
            editLoja: '',
            editId: '',
            editTipoUsuario: '',
            categoriasChecadas: [],
        };
    },

    created() {
        this.editNome = this.nome;
        this.editLoja = this.loja;
        this.editTipoUsuario = this.tipo_usuario;

        if (this.categoria) {
            const categorias = this.categoria.split(', ');

            this.categoriasChecadas = categorias;
        }
    },

    watch: {
        editNome(newValue) {
            if (newValue.trim().length > 4) {
                this.nomeInvalido = false;
            }
        },
        editLoja(newValue) {
            if (newValue != '') {
                this.lojaInvalida = false;
            }
        },
    },

    methods: {
        tipoHandler(event) {
            this.editTipoUsuario = event.target.value;
        },

        salvaEdicao() {
            if (this.validaEdicao() == false) return;

            const user = {
                nome: this.editNome,
                loja: this.editLoja,
                id: this.id,
                tipo_usuario: this.editTipoUsuario,
                categoria: this.categoriasChecadas,
            };

            this.$emit('salvaEdicao', user);
        },

        validaEdicao() {
            if (this.editNome.trim().length <= 4) {
                this.nomeInvalido = true;
                return false;
            }

            if (this.editLoja == '') {
                this.lojaInvalida = true;
                return false;
            }

            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.checkbox {
    display: block;

    p {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    &_categorias {
        display: grid;
        gap: 15px;
        font-size: 1rem;
        height: 250px;
        overflow-y: auto;
        overflow-x: hidden;

        @media (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    input {
        width: auto;
    }

    &_wrap {
        justify-content: unset;
    }
}
</style>
