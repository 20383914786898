<template>
    <Overlay @fechaOverlay="$emit('fechaOverlay')">
        <Card>
            <form class="form" @submit.prevent="salvaEdicao()">
                <div :class="{ error: senhaInvalida }">
                    <label for="senha">Nova Senha:</label>
                    <input type="password" id="senha" name="senha" v-model="senha" />
                </div>

                <small class="form_error-msg" v-if="senhaInvalida">Senha não pode ter menos de 4 caracteres</small>

                <Button texto="Salvar" />
            </form>
        </Card>
    </Overlay>
</template>

<script>
import Overlay from '../UI/Overlay.vue';
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';

export default {
    components: {
        Overlay,
        Card,
        Button,
    },

    props: {
        id: {
            required: true,
        },
    },

    data() {
        return {
            senhaInvalida: false,
            senha: '',
        };
    },

    watch: {
        senha(newValue) {
            if (newValue.trim().length > 4) {
                this.senhaInvalida = false;
            }
        },
    },

    methods: {
        salvaEdicao() {
            if (this.validaEdicao() == false) return;

            const userPassword = {
                senha: this.senha,
                id: this.id,
            };

            this.$emit('salvaNovaSenha', userPassword);
        },

        validaEdicao() {
            if (this.senha.trim().length <= 4) {
                this.senhaInvalida = true;
                return false;
            }

            return true;
        },
    },
};
</script>
